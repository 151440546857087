import React from "react";
import { navigate } from "gatsby";

import Layout from "../components/layout";
import Login from "../components/Login";
import { isLoggedIn } from "../utils/auth";

import Amplify from "aws-amplify";
import config from "../aws-exports";
Amplify.configure(config);

const IndexPage = () => {
  if (isLoggedIn()) {
    navigate("/app/home", { replace: true });
  }
  return <Layout>{!isLoggedIn() ? <Login /> : null}</Layout>;
};

export default IndexPage;
