/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:4aae7d0d-954d-4e65-8842-3e825c71c654",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6u3lrZAmK",
    "aws_user_pools_web_client_id": "6gerjhic0jfsrc5kq6dgfn070i",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://kizz4gz4hbh6va36lybho5s7uu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-kr62b25urfhrtfbwtlsq4kxhgu",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://uzwz5rpk02.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
